import { render, staticRenderFns } from "./ChemUCI51PLFS1Q2.vue?vue&type=template&id=3675a235&scoped=true&"
import script from "./ChemUCI51PLFS1Q2.vue?vue&type=script&lang=js&"
export * from "./ChemUCI51PLFS1Q2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3675a235",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCheckbox,VForm,VImg})
